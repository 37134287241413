@import '~bootstrap/scss/functions';
@import 'variables';

// RIDEFINISCO ALCUNE COSE SENZA MODIFICARLE, GIUSTO PER AVERLE DIPONIBILI >>>>>

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

// BOOTSTRAP OVERRIDES BEFORE IMPORT >>>>>

$enable-negative-margins: true;

$custom-container-max-widths: (
  xxl: 1434px,
);

$primary-font-family: 'Karla', sans-serif;
$secondary-font-family: 'GT America Extended', sans-serif;
$font-family-sans-serif: $primary-font-family;

$font-size-base: 1rem; // 16px`

$headings-font-family: $secondary-font-family;
$headings-font-weight: 700;

$display-font-weight: 700;

$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.125;

$display-font-sizes: (
  1: $font-size-base * 3.4375,
  2: $font-size-base * 3,
);

$small-font-size: $font-size-base * 0.875;
$extra-small-font-size: $font-size-base * 0.75;

$black: #000000;
$white: #ffffff;
$gray-100: #f8f8f8;
$gray-200: #f0f0f0;
$gray-300: #e6e6e6;
$gray-400: #c7c7c7;
$gray-500: #b4b4b4;
$gray-600: #14171e;
$red: #e2002a;
$promo: #e2002a;
$yellow: #ffc107;
$green: #198754;
$blue: #003b5b;

$custom-colors: (
  'black': $black,
  'white': $white,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'promo': $promo,
  'yellow': $yellow,
  'blue': $blue,
  'green': $green,
  'red': $red,
);

$border-radius: 1.5rem;
$border-radius-sm: 0.5rem;
$border-radius-lg: 3rem;

$body-bg: $white;
$body-color: $gray-600;

$primary: $red;
$secondary: $gray-600;

$table-variants: (
  'light': $gray-300,
);

$btn-font-weight: 700;
$input-border-color: $gray-400;
$input-placeholder-color: $gray-500;

$form-label-font-weight: 400;
$form-label-font-size: $small-font-size;

$border-radius: $border-radius;
$btn-border-radius-sm: $border-radius;
$input-btn-padding-y: 0.438rem;
$input-btn-padding-x: 1.6rem;

$form-check-input-checked-border-color: $gray-400;
$form-check-input-checked-bg-color: $white;
$form-check-input-checked-color: $red;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-check-input-checked-color}'/></svg>");
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg viewBox='-2 -2 14 14' xmlns='http://www.w3.org/2000/svg'><rect width='10' height='10' fill='#{$form-check-input-checked-color}'/></svg>");

$offcanvas-horizontal-width: 500px;
$offcanvas-padding-x: 3rem;
$offcanvas-border-width: 0;

$accordion-border-radius: 0;
$accordion-border-width: 0;
$accordion-button-active-color: $body-color;
$accordion-button-active-bg: transparent;
$accordion-button-focus-border-color: transparent;
$accordion-button-focus-box-shadow: transparent;

$nav-link-color: $secondary;
$nav-link-font-weight: bold;
$nav-link-hover-color: $white;
$nav-pills-link-active-color: $white;
$nav-pills-link-active-bg: $secondary;

$pagination-color: $gray-600;
$pagination-bg: transparent;
$pagination-disabled-bg: transparent;
$pagination-focus-bg: transparent;
$pagination-focus-color: $primary;
$pagination-hover-bg: transparent;
$pagination-active-color: $primary;
$pagination-active-bg: transparent;
$pagination-border-radius: 0;
$pagination-border-width: 0;
$pagination-padding-y: 0.5rem;
$pagination-padding-x: 0.5rem;
$pagination-padding-y-sm: 0.5rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.5rem;
$pagination-padding-x-lg: 0.5rem;

// Card
$card-border-width: 0;
$card-border-radius: $border-radius;
$card-bg: $white;

$modal-header-border-width: 0;
$modal-footer-border-width: 0;
$modal-content-border-radius: 17px;
$modal-content-border-width: 0;

// BOOTSTRAP OVERRIDES BEFORE IMPORT <<<<<

@import '../../node_modules/bootstrap/scss/variables';
//@import '/node_modules/bootstrap/scss/utilities';

$container-max-widths: map-merge($container-max-widths, $custom-container-max-widths);
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-text: map-merge($utilities-text, $custom-colors);
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, '$key', 'text');
$utilities-bg: map-merge($utilities-bg, $custom-colors);
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, '$key', 'bg');

/* $utilities: map-merge(
  $utilities,
  (
    "font-size": map-merge(
      map-get($utilities, "font-size"),
    (responsive: true),
    ),
  )
);
 */

.zindex-dropdown {
  z-index: $zindex-dropdown;
}
.zindex-sticky {
  z-index: $zindex-sticky;
}
.zindex-fixed {
  z-index: $zindex-fixed;
}
.zindex-modal-backdrop {
  z-index: $zindex-modal-backdrop;
}
.zindex-modal {
  z-index: $zindex-modal;
}
.zindex-popover {
  z-index: $zindex-popover;
}
.zindex-tooltip {
  z-index: $zindex-tooltip;
}

.input-group {
  > :not(:first-child):not(.dropdown-menu) {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(
    .invalid-feedback
  ) {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}

.form-check-input:focus {
  box-shadow: none;
}
.accordion-item {
  border-top: 1px solid $gray-400;
}

.nav-pills .nav-link {
  border: 1px solid $secondary;

  &:hover {
    background: $secondary;
  }
}

.form-group .form-label.required:after {
  color: $red;
  content: '*';
  margin-left: 0.25rem;
}

.pagination {
  .page-item {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    &.active {
      .page-link {
        font-weight: 700;
        border-bottom: 3px solid $red;
      }
    }
  }
}
