@import '../css/shared';

$pagination-height: 50px;

.slider-a {
  @include media-breakpoint-down(lg) {
    .text-block-coverer {
      .text-block-container {
        .text-block-resetter {
          .text-block {
            text-align: center;
            margin-top: $pagination-height;
            color: $body-color !important;
          }

          .disclaimer {
            text-align: center;
            color: $body-color !important;
          }
        }
      }
    }

    .pagination-wrapper {
      height: $pagination-height;
    }
  }

  @include media-breakpoint-up(lg) {
    img {
      width: 100%;
    }

    .text-block-coverer {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      .text-block-container {
        height: 100%;
        position: relative;

        .text-block-resetter {
          position: relative;
          height: 100%;

          .text-block {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            &.start {
              left: 0;
              right: 50%;
              text-align: left;
            }

            &.end {
              left: 50%;
              right: 0;
              text-align: right;
            }
          }

          .disclaimer {
            position: absolute;
            bottom: 10px;
            color: white;
            z-index: 4;

            &.start {
              left: 0;
              right: 50%;
              text-align: left;
            }

            &.end {
              left: 50%;
              right: 0;
              text-align: right;
            }
          }
        }
      }
    }
  }
}