@import 'css/shared';

.offcanvas {

  &.large-layout {
    width: 700px;
  }

  &.offcanvas-top {
    bottom: auto;
    height: auto;
  }

}