@import '../css/shared';

.home-page {
  .home-products-tabs {
    flex-wrap: nowrap;
    overflow-y: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .brands-section {
    .swiper-slide {
      margin-right: 18px !important;
    }

    @include media-breakpoint-up(sm) {
      .swiper-slide {
        margin-right: 40px !important;
      }
    }

    @include media-breakpoint-up(lg) {
      .swiper-slide {
        margin-right: 80px !important;
      }
    }

    .swiper-slide:last-child {
      margin-right: 0px !important;
    }
  }

  .newsletter-home-section {

    .newsletter-home-container {
      border-radius: 0rem;

      @include media-breakpoint-up(lg) {
        border-radius: $border-radius;
        overflow: hidden;
      }

    }

    img {
      object-fit: cover;
      height: 100%;
    }

    
  }
}