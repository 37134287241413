@import 'css/shared';

.footer {
 
  .accordion {
    .accordion-header button {
      box-shadow: none;
      &:after {
        background: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23FFF%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
      }
    }

    .accordion-item {
      // border-bottom: 0 !important;
      .accordion-header {
          margin-bottom: map-get($spacers, 2) !important;
          .accordion-button {
            font-family: $primary-font-family;
            font-weight: 700;
            text-transform: uppercase;
            font-size: $font-size-base;
          }
        }
     }
  }


  @include media-breakpoint-down(md) {
    .footer-payments {
        justify-content: center;
    }
    .footer-social {
      padding-bottom: 20px;
      border-bottom: 1px solid $gray-200;
    }
  }

  @include media-breakpoint-down(lg) {
      .container-xxl,
      .container-xxl .row {
        --bs-gutter-x: 0;
      }
      
    .Accordion {
      .row > [class^='col-'] {
        .accordion-item {
          border-bottom: 0;
        }
        &:last-child {
          .accordion-item {
            border-bottom: 1px solid $gray-200;
          }
        }  
      }
    }
  }
}