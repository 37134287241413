@font-face {
  font-family: 'GT America Extended';
  src: url('../fonts/GTAmerica-ExtendedBold.eot');
  src: url('../fonts/GTAmerica-ExtendedBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/GTAmerica-ExtendedBold.woff2') format('woff2'),
      url('../fonts/GTAmerica-ExtendedBold.woff') format('woff'),
      url('../fonts/GTAmerica-ExtendedBold.svg#GTAmerica-ExtendedBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Karla';
  src: url('../fonts/Karla-BoldItalic.eot');
  src: url('../fonts/Karla-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Karla-BoldItalic.woff2') format('woff2'),
      url('../fonts/Karla-BoldItalic.woff') format('woff'),
      url('../fonts/Karla-BoldItalic.svg#Karla-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Karla';
  src: url('../fonts/Karla-Regular.eot');
  src: url('../fonts/Karla-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Karla-Regular.woff2') format('woff2'),
      url('../fonts/Karla-Regular.woff') format('woff'),
      url('../fonts/Karla-Regular.svg#Karla-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Karla';
  src: url('../fonts/Karla-Medium.eot');
  src: url('../fonts/Karla-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Karla-Medium.woff2') format('woff2'),
      url('../fonts/Karla-Medium.woff') format('woff'),
      url('../fonts/Karla-Medium.svg#Karla-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Karla';
  src: url('../fonts/Karla-MediumItalic.eot');
  src: url('../fonts/Karla-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Karla-MediumItalic.woff2') format('woff2'),
      url('../fonts/Karla-MediumItalic.woff') format('woff'),
      url('../fonts/Karla-MediumItalic.svg#Karla-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Karla';
  src: url('../fonts/Karla-Bold.eot');
  src: url('../fonts/Karla-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Karla-Bold.woff2') format('woff2'),
      url('../fonts/Karla-Bold.woff') format('woff'),
      url('../fonts/Karla-Bold.svg#Karla-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Karla';
  src: url('../fonts/Karla-Light.eot');
  src: url('../fonts/Karla-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Karla-Light.woff2') format('woff2'),
      url('../fonts/Karla-Light.woff') format('woff'),
      url('../fonts/Karla-Light.svg#Karla-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

.fw-medium, .fw-semi-bold {
  font-weight: 500;
}

.ff-karla {
  font-family: 'Karla';
}

.extra-small {
    font-size: $extra-small-font-size;
}
  
[class^='display'] {
  font-family: $secondary-font-family;
}

.display-1 {
  font-size: $h3-font-size;
  @include media-breakpoint-up(sm) {
    font-size: $h2-font-size;
  }
  @include media-breakpoint-up(md) {
    font-size: $font-size-base * 1.875;
  }
  @include media-breakpoint-up(lg) {
    font-size: $h1-font-size;
  }
  @include media-breakpoint-up(xl) {
    font-size: $font-size-base * 2.625;
  }
  @include media-breakpoint-up(xxl) {
    font-size: $font-size-base *  3.4375;
  }
}

.display-2 {
  font-size: $h4-font-size;
  @include media-breakpoint-up(sm) {
    font-size: $h3-font-size;
  }
  @include media-breakpoint-up(md) {
    font-size: $h2-font-size;
  }
  @include media-breakpoint-up(lg) {
    font-size: $font-size-base * 2;
  }
  @include media-breakpoint-up(xl) {
    font-size: $font-size-base * 2.5;
  }
  @include media-breakpoint-up(xxl) {
    font-size: $font-size-base * 3;
  }
 
}