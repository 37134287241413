@import '../css/shared';

.search-page {
  
  .order-dropdown {
    button:after {display: none;}

    .dropdown-item.active, .dropdown-item:active { 
      color: $secondary;
      background-color: $white;
      font-weight: 700;
    }

    ul {
      top: -2px !important;
      padding: 0;
      border: 0;
      width: 100%;
    }
  }

/*   .pagination {
    .page-item {
      @include media-breakpoint-up(lg) {
        padding: 0 map-get($spacers, 2);
      }

      .page-link {
        border: 0;
        color: $secondary;
        background: transparent;
        border-radius: 0;
        padding: map-get($spacers, 2);
      }

      &.active {
        font-weight: 700;
        .page-link {
          border-bottom: 3px solid $secondary;
        }
      }
    }
  } */

  .search-filters-container {
    @include media-breakpoint-up(lg) {
      position: sticky;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .show-mobile-search-filter {
      color: $body-color;
      text-align: center;
      text-decoration: none;
      font-weight: 700;
      border-top: 1px solid $gray-300;
      border-bottom: 1px solid $gray-300;
      border-radius: 0;
      margin-bottom: spacer(4);
    }

    .search-filters > ul {
      display: flex;
      flex-direction: column;
      .search-result {display: none;}
    }
  }

  .search-filters {
    button {
        color: $secondary !important;
    }
    .main-category {
      .btn,
      .form-check-label {
        text-transform: uppercase;
        font-weight: 500;
        color: $secondary;
      }
    }
    .form-check {
      font-size: $h6-font-size;
    }
    .form-check-input {
        border-color: $secondary;
      }
    
    .accordion-header {
      .form-check-label {
        font-weight: 500;
      }
    }
    .accordion-item {
      background-color: $gray-100;
    }
    .filter-type-facet, .filter-type-brand {
      > div {
        color: $secondary !important;
        font-weight: 500 !important;   
        text-transform: uppercase;     
      }
    }
  }

// // Scrollbar customization - Start
@include media-breakpoint-up(lg) {
    ::-webkit-scrollbar-track-piece:start {
        margin-top: 3rem;
    }

    ::-webkit-scrollbar-track-piece:end {
        margin-bottom: 3rem; 
    }

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track-piece {
        background: $gray-200;
        border-radius: $border-radius;
    }

    ::-webkit-scrollbar-thumb {
        background: $gray-300;
        border-radius: $border-radius;
    }

    // // Scrollbar customization - End
  }
 
}