@import '../css/shared';

.service-page {
  h1 {
    font-size: $h1-font-size
  }
  .aside-menu-title {
//    color: $blue;
    font-weight: 700;
    background-color: $white !important;
    margin-bottom: 0 !important;
  }
  .aside-menu-item {
    background-color: $gray-100 !important;
  }
  .faq-title {
    text-transform: uppercase;
    font-weight: 500;
  }

  .subpage-title-container{
    border: 0 !important;
  }

  .box-content {
    a {
      color: $secondary !important;
    }
  }

}