@import 'css/shared';

.product-tile {

  .product-tile-stickers-container {
    pointer-events: none;
  }

  @include media-breakpoint-up(md) {}

  @include media-breakpoint-down(md) {}

  .swiper {
    &.auto-width {
      .swiper-slide {
        width: auto;
      }
    }
  }

  input[type="radio"] {

    &+label {

      .custom-sku-radio {
        position: relative;
        overflow: hidden;
      }

      .custom-sku-radio {

        .sku-image {

          div {
            width: 40px;
            height: 40px;
            border-radius: $border-radius-sm;
            overflow: hidden;
            border: 1px solid $gray-300;
          }

        }

        .sku-color {
          margin: 14px 0;

          div {
            border: 1px solid $gray-300;
            box-shadow: 0 0 0 3px inset $white;
            width: 26px;
            height: 26px;
            border-radius: 50%;
          }

        }

      }

    }

    &:checked+label {

      .sku-color {

        div {
          border-color: $black;
          box-shadow: 0 0 0 3px inset $white;
        }

      }

      .sku-image {

        div {
          border-color: $black;
        }

      }

    }

    &:disabled+label {

      .custom-sku-radio .sku-color div,
      .custom-sku-radio .sku-image image {
        opacity: 0.5;
      }

      .custom-sku-radio {

        &:before {
          content: '';
          position: absolute;
          height: 1px;
          width: 100%;
          background: $gray-200;
          transform: rotate(45deg);
          transform-origin: center;
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          top: 0;
          margin: auto;
          z-index: 10;
        }

      }

    }

  }

  .tile-quantity-container {

    .btn-container {
      width: 40px;
      display: grid;
    }

    input {
      width: 100%;
    }

    input[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
    }

    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      appearance: none
    }

  }

}