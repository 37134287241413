@import '../shared';

.cart-sku {
  .product-name {
    margin-bottom: map-get($spacers, 2) !important;
    font-size: $h6-font-size;
  }
  .text-offer {
    color: $promo;
  }

  .product-sku-name {
    font-family: 'Karla';
    font-weight: 400;
  }
}
