@import '../css/shared';

.cart-page {
  .cart-packs {
    border-radius: $border-radius;
    .cart-pack {
      &>.cart-sku:first-child>div {
        padding-top: 0 !important;
      }
      &>.cart-sku:last-child>div {
        padding-bottom: 0 !important;
      }
      .cart-sku {
        .SeparatedList {
          .btn {
            font-weight: 400;
          }
        }
        .text-offer {
          color: $promo;
        }
        .cart-sku-info ul li {
          font-size: $small-font-size;
        }
        @include media-breakpoint-up(lg) {
          .cart-sku-action {
            .cart-sku-price {
              font-size: $h6-font-size;
            }

            &>div:first-child {
              &>.row>.col {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                &:first-child {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }

    img.img-fluid {
      @include media-breakpoint-up(lg) {
        max-width: 105px !important;
      }
      max-width: 105px !important;
    }
  }

  .cart-summary {
    .cart-to-free-shipping {
      font-weight: 400;
      padding: 0;
      padding: 20px 0 0 !important;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      > div {
        padding: 0 15px;
        text-align: left;
      }
      .progress {
        border-radius: 0;
        height: 5px !important
      }

    }
    &>div:last-child {
        border-radius: $border-radius;        
        h5 {
            font-size: $h3-font-size;
            font-weight: 700;
        }
    }
  }

  .cart-footer {
    border-radius: $border-radius;
    @include media-breakpoint-up(lg) {
      padding: map-get($spacers, 4) map-get($spacers, 3) !important;
      h6 {
        margin-bottom: map-get($spacers, 4);
      }
    }
  }

  .Accordion {
    .row>[class^='col-'] {
      .accordion-item {
        border-bottom: 0;
        border-color: $gray-200;
      }
      &:last-child {
        .accordion-item {
          border-bottom: 1px solid $gray-200;
        }
      }
    }
    .accordion-item {
      border-left: 0;
      border-right: 0;
    }
    .accordion-button:not(.collapsed) {
      box-shadow: none !important;
    }
    .accordion-body {
      button[type="submit"] {
        color: $secondary;
        border-color: $gray-200 !important;
      }
    }
    @include media-breakpoint-down(lg) {}
    @include media-breakpoint-up(lg) {}
  }
  .cart-to-free-shipping {
    background: $white;
  }
  .generic-coupon-form {
    input {
      border-color: $gray-200;
      &:focus {
        border-color: $primary;
      }
      &.is-invalid {
        border-color: $red;
      }
    }
    input+.btn {
      border-left: 1px solid $gray-200 !important;
    }
    input:focus+.btn {
      border-color: $primary !important;
      border-left: 1px solid $primary !important;
    }
    input.is-invalid+.btn {
      border-color: $red !important;
      border-left: 1px solid $red !important;
    }
  }

  #form-cartGenericCoupon {
    button {
      z-index: 100;
      opacity: 1 !important;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 1px;
        height: calc(100% - 14px);
      }
    }
  }
}