@import '../css/shared';

.account-page {

  .PageWithSubpageHeader {

    .title {
      font-size: $h1-font-size;
    }
    
    .subpage-title {
      font-size: $h4-font-size;
      text-transform: uppercase;
    }
  }

  .box-content {
    box-shadow: none !important;
    a {
      color: $primary !important;
    }
  }
 
  .menu-account {
    .btn {
      font-weight: normal;
      text-transform: initial;
    }

    .list-group {

      .list-group-item {
        border-radius: 0;
        margin-bottom: spacer(1) !important;
        font-weight: 500;

        &.active {
          background-color: $primary !important;
          color: $white !important;
          .Icon {
            color: $white !important;
          }
        }
      }
    }
  }
}