@import '../css/shared';

:root {
  --swiper-pagination-color: #{$primary};
  --swiper-pagination-bullet-inactive-color: #{$gray-200};
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-size: 11px;
  // --swiper-pagination-bullet-horizontal-gap: 5px;

  @include media-breakpoint-up(lg) {
    // --swiper-pagination-bullet-size: 10px;
    // --swiper-pagination-bullet-horizontal-gap: 3px;
  }
}

@import '../../node_modules/@b2x/react/src/Swiper.scss';