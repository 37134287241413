@import 'css/shared';

.product-page {

  .price-block {

    .crossed-out-price,
    .price {
      font-size: $h4-font-size;
    }

    .crossed-out-price,
    .discount-percentage {
      margin-right: map-get($spacers, 2 );
    }

    .discount-percentage {
      padding: 0 map-get($spacers, 2 );
      background-color: $promo;
      font-size: $small-font-size;
      font-weight: 500;
    }

    .special-price {
      color: $promo;
    }

  }
 
}

.product-sticky-footer {

  .price-block {

    .crossed-out-price {
      margin-right: map-get($spacers, 2 );
    }

    .discount-percentage {
      display: none !important;
    }

    .special-price {
      color: $promo;
    }

  }

  .product-sticky-footer-mobile {
    
    button[type="submit"] {
      margin: map-get($spacers, 1) map-get($spacers, 2) map-get($spacers, 1) map-get($spacers, 1);
      padding: 8px 20px;
  
      svg {
        font-size: $h4-font-size !important;
      }
  
    }
  
  }
 
}

.product-tile {

  .price-block {

    .special-price {
      color: $promo;
    }

    @include media-breakpoint-up(sm) {
      font-size: $h5-font-size;
    }
    @include media-breakpoint-down(sm) {
      font-size: $small-font-size;
    }

  }
  
}
