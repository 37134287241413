@import '../css/shared';

.product-page {

  .ringbell {
      width: 100% !important;
  
      svg {
        width: 50px !important;
        height: 50px !important;
      }
  
    }

  .product-gallery {
    .gallery-thumbnails,
    .gallery-slides {
      .swiper-slide {
        background: $gray-100;
      }
      >.gallery-product-badge {
        z-index: 2;
      }
    }

    .gallery-thumbnails {
      .swiper {
        margin: - map-get($spacers, 2);
        padding: map-get($spacers, 2);
      }
      .swiper-slide {
        border: 1px solid transparent;
        &.border {
          border-color: $gray-300 !important;
          // box-shadow: 0 0 6px 1px rgba(var(--bs-light-blue-rgb), 0.4) ;
        }
      }
    }

    .swiper-pagination-bullet {
      opacity: 1;
      background: $gray-200;
      &.swiper-pagination-bullet-active {
        background: $primary;
      }
    }

    .gallery-slides {
      width: 100%;
    }
  }

  .quantity-container {
    .btn-container {
      width: 40px;
      display: grid;
    }

    input {
      width: 100%;
    }

    input[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
    }

    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
      appearance: none
    }

  }

  .related-products  {
    overflow: hidden;
  }

  .kit-content-section {
    .swiper-button-disabled {
      display: none;
    }

    @include media-breakpoint-down(lg) {
      .swiper-button-next,
      .swiper-button-prev {
        display: none;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .product-gallery {
      .gallery-thumbnails {
        width: 50px;
      }
      .gallery-slides {
        max-width: calc(100% - 50px);
      }
      .swiper-pagination {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .product-first-section {
      .container-xxl,
      .container-xxl .row {
        --bs-gutter-x: 0;
      }
    }

    .related-products  {
      overflow: hidden;
      .swiper {
        width: 100%;
        overflow: visible;
      }
    }
  }

  .product-promo-code {
    border-radius: 10px;
    @include media-breakpoint-down(md) {
      .button-col {
        text-align: center;
      }
    }
  }

  .product-files {

    .accordion {

      & > .row {
        gap: map-get( $spacers, 3 );
      }

      .accordion-item {
        background: transparent;
        border: 0;

        .accordion-header {

          .accordion-button {
            background: #E0E8EC;
            color: $blue;
            text-transform: uppercase;
            border-radius: $border-radius-lg;
            font-family: 'Karla';
            font-weight: 700;
            padding: 0.65rem 1.6rem;

            &:after {
              background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 40 32%27 fill=%27%23003B5B%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M20.184 20.367l-13.128-13.128c-0.8-0.8-2.111-0.8-2.925 0-0.8 0.8-0.8 2.111 0 2.925l14.597 14.597c0.092 0.092 0.197 0.184 0.315 0.262 0.052 0.039 0.105 0.066 0.157 0.092 0.066 0.039 0.131 0.066 0.197 0.105 0.066 0.026 0.144 0.052 0.21 0.066 0.052 0.013 0.118 0.039 0.171 0.052 0.131 0.026 0.275 0.039 0.407 0.039v0c0.131 0 0.262-0.013 0.393-0.039 0.066-0.013 0.118-0.039 0.184-0.052s0.131-0.039 0.21-0.066c0.066-0.026 0.144-0.066 0.21-0.105 0.052-0.026 0.105-0.052 0.144-0.079 0.118-0.079 0.223-0.171 0.315-0.262l14.61-14.597c0.8-0.8 0.8-2.111 0-2.925-0.8-0.8-2.111-0.8-2.925 0l-13.141 13.141z%27/%3e%3c/svg%3e');
              height: 16px;
            }

          }

        }

        .accordion-body {
          padding: 1rem 1.6rem 1.6rem;
        }

      }

    }

  }


}

.modal.modal-copy-coupon {
  .modal-header {
    justify-content: center;
    padding-bottom: 0 !important;
    .modal-title {
      font-size: $h3-font-size !important;
    }
  }
  .modal-body {
    text-align: center !important;
  }
}