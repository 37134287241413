@import '../css/shared';

.checkout-page {

  .checkout-step {
    
    .card {
      border-radius: 0;
      border-color: $white;
      .btn-blank {
        font-weight: 400;
        
      }
      .card-body.show {

        &.bg-white {
          border: 0;
          border-radius: $border-radius;
          overflow: hidden;
        }

      }

    }
    
  }

  .checkout-summary {
    border: 0;
    border-radius: $border-radius;
    overflow: hidden;

    h5 {
      padding: 15px 0;
      font-size: $h3-font-size;
      font-weight: 500;
    }

    img.img-fluid {
      @include media-breakpoint-up(lg) {
        max-width: 160px !important;
      }
      max-width: 120px !important;
      border: 1px solid $gray-200;
      border-radius: $border-radius;
    }

    .cart-sku-price {font-size: $h5-font-size;}

  }
  
  @include media-breakpoint-down(lg) {}
  @include media-breakpoint-up(lg) {}

}