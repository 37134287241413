@import '../css/shared';

.newsletter-subscribe-page {

  .form-check-label {
    a {
      color: $white !important;
    }
  }

  .left-box {
    @include media-breakpoint-up(lg) {
      width: 50% !important;
    }
  }

  .right-box {
    background-color: $blue;
    @include media-breakpoint-up(lg) {
      width: 50% !important;
    }
  }
  
  .NewsletterForm {
    .cta-section  {
        @include media-breakpoint-down(md) {
          text-align: center !important;
          display: block !important;
        }
    }
  }
    
}

