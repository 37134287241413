@import 'css/shared';

.page-header {

  .page-header-container {
    background: $gray-200;

    &.text-white {

      .breadcrumb {

        .breadcrumb-item:before, span, a { color: $white; }

      }
  
    }

  }

  @include media-breakpoint-up(lg) {

    .page-header-container {
      border-radius: $border-radius;
    }

  }

  @include media-breakpoint-down(lg) {

    .container-xxl,
    .container-xxl .row {
      --bs-gutter-x: 0;
    }

    .breadcrumb { justify-content: center; }

    .breadcrumb-container,
    .title-container {
      padding: 0 map-get( $spacers, 3 );
    }

    .title-container,
    .short-description-container {
      text-align: center;
    }

    .title-container {
      margin-bottom: 1rem;
    }

    .short-description-container {
      background: $gray-100;
      color: $black;
      padding: map-get( $spacers, 3 );
    }

  }

}