@import '../css/shared';

.vertical-slider {
  width: 100%;

  .swiper {
    height: 30px;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-pagination {
      position: absolute;
      left: 0;
      top: auto;
      right: 0;
      bottom: 4px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: none;

        .swiper-pagination-bullet {
            background-color: var(--bs-white) !important;
            opacity: .6 !important;
            width: 6px !important;
            height: 6px !important; 
            margin: 0 3px !important;
            }

        .swiper-pagination-bullet-active {
            background-color: var(--bs-white) !important;
            opacity: 1 !important;
        }
    }
  }
}
