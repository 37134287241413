@import 'css/shared';

.mobile-navigation-offcanvas {
    .Drawers {
        .Drawer {
            .drawer-see-all-link {
                background-color: $gray-100;
                font-weight: 700;
                text-decoration: underline !important;
            }
            .drawer-back-button {
                background-color: $gray-100;
            }
            .Button {
                border-radius: 0;
                border-color: $gray-100 !important;
            }
        }
    }
}