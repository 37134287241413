@import 'functions';
@import 'bootstrap';
@import 'swiper';
@import 'mixins';
@import 'fonts';
@import 'b2x-components';
@import 'b2x-theme/b2x-theme';

// Decommentare questo stile per debuggare i CSS.
// * {
//   background-color: rgba(green, 0.05);
// }

:root {
  --swiper-theme-color: $secondary;
}

body {
  overflow-y: scroll;
}

.btn-white {
  color: $red;
}
.btn-outline-gray-400 {
  color: $gray-600;
}
.text-initial {
  text-transform: initial !important;
}

/*// Partial border radius //*/

.rounded-0-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rounded-1-top {
  border-top-left-radius: $border-radius-sm;
  border-top-right-radius: $border-radius-sm;
}
.rounded-2-top {
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}
.rounded-3-top {
  border-top-left-radius: $border-radius-lg;
  border-top-right-radius: $border-radius-lg;
}

.rounded-0-end {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rounded-1-end {
  border-top-right-radius: $border-radius-sm;
  border-bottom-right-radius: $border-radius-sm;
}
.rounded-2-end {
  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}
.rounded-3-end {
  border-top-right-radius: $border-radius-lg;
  border-bottom-right-radius: $border-radius-lg;
}

.rounded-0-bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.rounded-1-bottom {
  border-bottom-left-radius: $border-radius-sm;
  border-bottom-right-radius: $border-radius-sm;
}
.rounded-2-bottom {
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
}
.rounded-3-bottom {
  border-bottom-left-radius: $border-radius-lg;
  border-bottom-right-radius: $border-radius-lg;
}

.rounded-0-start {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rounded-1-start {
  border-top-left-radius: $border-radius-sm;
  border-bottom-left-radius: $border-radius-sm;
}
.rounded-2-start {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}
.rounded-3-start {
  border-top-left-radius: $border-radius-lg;
  border-bottom-left-radius: $border-radius-lg;
}

.rounded-0-top-start {
  border-top-left-radius: 0;
}
.rounded-0-top-end {
  border-top-right-radius: 0;
}
.rounded-0-bottom-start {
  border-bottom-left-radius: 0;
}
.rounded-0-bottom-end {
  border-bottom-right-radius: 0;
}

.rounded-1-top-start {
  border-top-left-radius: $border-radius-sm;
}
.rounded-1-top-end {
  border-top-right-radius: $border-radius-sm;
}
.rounded-1-bottom-start {
  border-bottom-left-radius: $border-radius-sm;
}
.rounded-1-bottom-end {
  border-bottom-right-radius: $border-radius-sm;
}

.rounded-2-top-start {
  border-top-left-radius: $border-radius;
}
.rounded-2-top-end {
  border-top-right-radius: $border-radius;
}
.rounded-2-bottom-start {
  border-bottom-left-radius: $border-radius;
}
.rounded-2-bottom-end {
  border-bottom-right-radius: $border-radius;
}

.rounded-3-top-start {
  border-top-left-radius: $border-radius-lg;
}
.rounded-3-top-end {
  border-top-right-radius: $border-radius-lg;
}
.rounded-3-bottom-start {
  border-bottom-left-radius: $border-radius-lg;
}
.rounded-3-bottom-end {
  border-bottom-right-radius: $border-radius-lg;
}
