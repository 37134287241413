@import '../css/shared';

.customer-form-b {
  @include media-breakpoint-down(lg) {
    .btn {
      width: 100%;
    }
  }
  .box-newsletter-radio {
    background-color: $blue;
    border-radius: $border-radius;
    text-align: center;
    color: $white;
    padding: spacer(3);
    .title {
      font-weight: 700;
      font-size: $h4-font-size;
    }
    .radio {
      color: $white;
      font-size: $small-font-size;
    }
  }
}

.form-select[name='quantity'] {
  width: 80px;
  padding-left: 15px;
  padding-right: 15px;
  background-position: right 1rem center;
}

.cart-sku-action {
  padding-top: spacer(3) !important;
}

.Breadcrumb {
  ol {
    margin: 0;
  }

  .breadcrumb-item {
    font-size: $font-size-base * 0.75;

    a {
      color: $secondary;
      text-decoration: none;
    }

    & + .breadcrumb-item:before {
      content: '';
      float: none;
      padding: 0;
      display: inline-block;
      width: 6px;
      height: 6px;
      top: -1px;
      position: relative;
      margin-right: 11px !important;
      border-right: 1px solid $gray-400;
      border-bottom: 1px solid $gray-400;
      transform: rotate(-45deg);
    }

    &.active {
      font-weight: 300;
    }
  }
}
