@import 'css/shared';

.account-offcanvas {

  .offcanvas-title,
  .offcanvas-body-title {
    font-size: $h2-font-size;
    font-weight: 700;
  }

  .offcanvas-body-registration-wrapper {
    position: relative;
    background: $gray-600 !important;
    color: $white;
  }

  .offcanvas-body {

    & > div:first-child {
      padding-top: 1.5rem !important;
    }

  }

}